<template>
  <div>
    <upload-sales-data />
    <upload-outstanding-bills />
    <upload-bills-zip />
  </div>
</template>

<script>
import UploadSalesData from '@/components/Uploads/UploadSalesData.vue'
import UploadOutstandingBills from '@/components/Uploads/UploadOutstandingBills.vue'
import UploadBillsZip from '@/components/Uploads/UploadBillsZip.vue'

export default {
  components: {
    UploadSalesData,
    UploadOutstandingBills,
    UploadBillsZip,
  },
}
</script>

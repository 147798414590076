<template>
  <b-card title="Upload Outstanding Bills">
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="3">
          <b-form-group
            label="Party outstanding file"
            label-for="party-outstanding-file"
          >
            <b-form-file
              id="party-outstanding-file"
              v-model="partyOutstandingFile"
              placeholder="Party outstanding file"
              accept=".xlsx"
            />
          </b-form-group>
        </b-col>

        <b-col lg="2">
          <b-button
            variant="primary"
            :disabled="submitting"
            type="submit"
            class="mt-2"
          >
            Submit
            <b-spinner
              v-if="submitting"
              small
              label="Small Spinner"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="5">
          <span v-if="lastUploaded">Last Uploaded at: {{ formatedDate(lastUploaded, 'DD-MM-YYYY hh:mm:ss A') }}</span>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormFile, BButton, BSpinner, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import dateMixin from '@/mixins/dateMixin'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    BSpinner,
    BAlert,
  },
  mixins: [dateMixin],
  data() {
    return {
      partyOutstandingFile: null,
      errorMessage: null,
      submitting: false,
      lastUploaded: null,
    }
  },
  created() {
    this.fetchLastUploaded()
  },
  methods: {
    onSubmit() {
      this.errorMessage = null
      this.submitting = true

      const formData = new FormData()
      formData.append('party_outstanding_file', this.partyOutstandingFile)

      axios.post('/accounts/upload_outstanding_bills',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.partyOutstandingFile = null

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.submitting = false
          this.fetchLastUploaded()
        }).catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Something went wrong'
          this.submitting = false
        })
    },
    fetchLastUploaded() {
      axios.get('/accounts/meta_info').then(res => {
        if (res.data.outstanding_data_updated) {
          this.lastUploaded = res.data.outstanding_data_updated
        }
      })
    },
  },
}
</script>

<template>
  <b-card title="Upload Bills Zip">
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </b-alert>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="3">
          <b-form-group
            label="Bills Zip File"
            label-for="zip-file"
          >
            <b-form-file
              id="zip-file"
              v-model="billsZipFile"
              placeholder="Bills Zip file"
              accept=".zip"
            />
          </b-form-group>
        </b-col>

        <b-col lg="2">
          <b-button
            variant="primary"
            :disabled="submitting"
            type="submit"
            class="mt-2"
          >
            Submit
            <b-spinner
              v-if="submitting"
              small
              label="Small Spinner"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormFile, BButton, BSpinner, BAlert, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      billsZipFile: null,
      errorMessage: null,
      submitting: false,
    }
  },
  methods: {
    onSubmit() {
      this.errorMessage = null
      this.submitting = true

      const formData = new FormData()
      formData.append('zip_file', this.billsZipFile)

      axios.post('/accounts/upload_bills_zip',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.billsZipFile = null

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.submitting = false
        }).catch(error => {
          this.errorMessage = error?.response?.data?.error || 'Something went wrong'
          this.submitting = false
        })
    },
  },
}
</script>
